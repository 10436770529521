.header{
    /* margin-top: 20px; */
    width: 100%;
    position:fixed;
    top:0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav{
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav__logo,
.nav__toggle{
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.glitch{
    text-transform: uppercase;

    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
    -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
    0.025em 0.05em 0 rgba(0, 0, 255, 0.75);

    animation: glitch 500ms infinite;
  }


  
.glitch span {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .glitch span:first-child {
    animation: glitch 650ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
    transform: translate(-0.025em, -0.0125em);
    /* color: green; */
    opacity: 0.8;
  }
  
  .glitch span:last-child {
    animation: glitch 375ms infinite;
    clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
    transform: translate(0.0125em, 0.025em);
    /* color: red; */
    opacity: 0.8;
  }
  

  @keyframes glitch {
    0% {
      text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
        -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
        -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
    }
    14% {
      text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
        -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
        -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
    }
    15% {
      text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
        0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
        -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
    }
    49% {
      text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
        0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
        -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
    }
    50% {
      text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
        0.05em 0 0 rgba(0, 255, 0, 0.75), 0 -0.05em 0 rgba(0, 0, 255, 0.75);
    }
    99% {
      text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
        0.05em 0 0 rgba(0, 255, 0, 0.75), 0 -0.05em 0 rgba(0, 0, 255, 0.75);
    }
    100% {
      text-shadow: -0.025em 0 0 rgba(255, 0, 0, 0.75),
        -0.025em -0.025em 0 rgba(0, 255, 0, 0.75),
        -0.025em -0.05em 0 rgba(0, 0, 255, 0.75);
    }
  }

.nav__list{
    display:flex;
    column-gap: 2rem;
}

.nav__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: .3s;
}

.nav__icon,
.nav__close,
.nav__toggle{
    display: none;
}

/* Active Link */

.active-link,
.nav__link:hover{
    color: var(--title-color-dark);
    text-shadow: .5px .5px 0 var(--light-mode-shadow);
    border-bottom: 1px solid var(--light-mode-shadow);
}
/* change background header */

.scroll-header{
    box-shadow: 0 -1px 4px rgba(0,0,0,0.1);
}


/* Breakpoints */
/* For medium devices */
@media screen and (max-width: 768px) {
    .header{
        top: initial;
        bottom: 0;
        /* margin-bottom: 20px; */
    }

    .nav{
        height: var(--header-height);
    }

    .nav__menu{
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }

    /* show menu */

    .show-menu{
        bottom: 0;
    }

    .nav__list{
        display:grid;
        grid-template-columns: repeat(3,1fr);
        gap: 2rem;
    }

    .nav__icon{
        font-size: 1.2rem;
    }

    .nav__close{
        position:absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav__close:hover{
        color:var(--title-color-dark);
        text-shadow: .5px .5px 0 var(--light-mode-shadow);

    }

    .nav__toggle{
        font-size: 1.1rem;
    }

    .nav__icon,
    .nav__close,
    .nav__toggle{
        display: block;
    }

    .active-link,
.nav__link:hover{
    border-bottom:none;
}
}

@media screen and (max-width: 350px) {

    .nav__menu{
        padding: 2rem 0.25rem 4rem;
    }

    .nav__list{
        column-gap: 0;
    }

}

@media (prefers-reduced-motion: reduce) {
    *,
    ::before,
    ::after {
      animation-delay: -1ms !important;
      animation-duration: 1ms !important;
      animation-iteration-count: 1 !important;
      background-attachment: initial !important;
      scroll-behavior: auto !important;
      transition-duration: 0s !important;
      transition-delay: 0s !important;
    }
  }
  