.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 2rem;
  color: var(--title-color);
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);
}



.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  padding-left: 5.4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
}

.home__subtitle::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--title-color);
  left: 0;
  top: 1rem;
}

.home__description {
  max-width: 400px;
  margin-bottom: var(--mb-3);
}

.home__social-icon:hover {
  color: var(--title-color-dark);
  text-shadow: .5px .5px 0 var(--light-mode-shadow);
}

.home__img {
  background: url("../../assets/pic2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255 255 255/30%);
  order: 1;
  justify-self: center;
  width: 300px;
  height: 300px;
  animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {

  .home__content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home__subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home__description {
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home__img {
    width: 250px;
    height: 250px;
    box-shadow: inset 0 0 0 7px rgb(255, 255, 255/30%);
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .home__content {
    grid-template-columns: 0.8fr 3fr;
    padding-top: 3.5rem;
  }

  .home__social-icon {
    font-size: 1.5rem;
   
  }

  .home__img {
    order: initial;
    justify-self: initial;
  }

  .home__data {
    grid-column: 1/3;
  }

  .home__img {
    box-shadow: inset 0 0 0 6px rgb(255, 255, 255/30%);
    width: 200px;
    height: 200px;
  }


}






/* For small devices */
@media screen and (max-width: 350px) {


  .home__social-icon {
    font-size: 1rem;
    
  }


  .home__img {
    width: 180px;
    height: 180px;
  }
}



